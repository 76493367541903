import React from "react";
import { Helmet } from "react-helmet";
// Sections
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Footer from "../components/Sections/Footer";
import AboutUs from "../components/Sections/About";
import WhyChooseUs from "../components/About/whyChooseus";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>Home | Smoothsync Innovations</title>
        <meta
          name="description"
          content="Welcome to Smoothsync Innovations, your partner for cutting-edge web and software solutions. Explore our services and find out why we're the right choice for your business."
        />
        <meta
          name="keywords"
          content="Smoothsync Innovations, web development, software solutions, React.js, Node.js, custom applications, IT services"
        />
        <meta name="author" content="Smoothsync Innovations" />
        <meta name="robots" content="index, follow" />
     
      </Helmet>

      <Header />
      <Services />
      <AboutUs />
      <WhyChooseUs />
      <Footer />
    </>
  );
}
